//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import PageHeader from '@/components/page-header';
    import HeaderFormItem from '@/components/page-header/header-form-item';

    export default {
        components: {HeaderFormItem, PageHeader},
        inject: ['hideLoading', 'showLoading'],
        computed: {
            userList() {
                return this.$store.state['userManagement'].userList;
            },
            userTotal() {
                return this.$store.state['userManagement'].userTotal;
            },
            deptList() {
                return this.$store.state['department'].treeList;
            },
        },
        data() {
            return {
                queryParams: {
                    username: '',
                    departmentId: null,
                    status: null,
                    pageSize: 10,
                    pageNum: 1,
                },
                props: {
                    value: 'id',
                    label: 'name',
                    children: 'childrenList',
                    checkStrictly: true,
                },
            };
        },
        created() {
            this.getUserList();
        },
        mounted() {
            this.hideLoading();
            this.selectFocus();
        },
        methods: {
            //顶部查询
            search() {
                this.queryParams.pageNum = 1;
                this.getUserList();
            },
            //查询用户信息
            async getUserList() {
                this.showLoading();
                let res = await this.$store.dispatch('userManagement/getAllUserList', this.queryParams);
                this.hideLoading();
                if (res.code !== 200) {
                    this.$notify({
                        title: res.code,
                        message: res.msg,
                        type: 'error',
                        duration: 2000,
                        position: 'bottom-right',
                    });
                }
            },
            //级联选择器获得焦点
            selectFocus() {
                this.$store.dispatch('department/getTree');
            },
            // 处理角色授权按钮
            async handlerRoleClick(item) {
                // 选中的用户列表
                await this.$store.dispatch('userManagement/changeActiveItem', item);
                // 通过用户查询角色列表
                await this.$store.dispatch('userManagement/getUserRoleInfo', item.id);
                // 打开角色选择弹窗
                this.$emit('showRoleAuthorization', true);
            },
            //级联选择器触发子菜单
            handleChangeSelect(val) {
				if (val){
					this.queryParams.departmentId = val[val.length - 1];
				}
            },

            //增加用户信息
            addHandler() {
                this.$emit('showAdd', true);
            },

            //修改USER
            modifyHandler(item) {
                this.$store.dispatch('userManagement/changeActiveItem', item);
                this.$emit('showModify', true);
            },
            //删除用户信息
            removeMenuItem(item) {
                this.$confirm(`是否删除该用户(${item.username})?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                })
                    .then(async () => {
                        this.showLoading();
                        let changeRes = await this.$store.dispatch('userManagement/removeUserInformation', {id: item.id});
                        this.hideLoading();
                        let type = 'error';
                        if (changeRes.code === 200) {
                            this.search();
                            type = 'success';
                        }
                        this.$notify({
                            title: changeRes.code,
                            message: changeRes.msg,
                            type: type,
                            duration: 2000,
                            position: 'bottom-right',
                        });
                    })
                    .catch(() => {
                    });
            },

            //禁止和启用用户
            prohibitUserHandler(item) {
                let isEnable = '启用';
                let status = 1;
                if (item.status === 1) {
                    isEnable = '禁止';
                    status = 0;
                }
                this.$confirm(`是否${isEnable}该用户(${item.username})?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                })
                    .then(async () => {
                        this.showLoading();
                        let changeRes = await this.$store.dispatch('userManagement/prohibitUser', {
                            id: item.id,
                            status: status,
                        });
                        this.hideLoading();
                        let type = 'error';
                        if (changeRes.code === 200) {
                            this.search();
                            type = 'success';
                        }
                        this.$notify({
                            title: changeRes.code,
                            message: changeRes.msg,
                            type: type,
                            duration: 2000,
                            position: 'bottom-right',
                        });
                    })
                    .catch(() => {
                    });
            },

            //选择每一页的数据量
            handleSizeChange(val) {
                this.queryParams.pageNum = 1;
                this.queryParams.pageSize = val;
                this.getUserList();
            },

            //选择当前页页码
            handleCurrentChange(val) {
                this.queryParams.pageNum = val;
                this.getUserList();
            },
        },
    };
