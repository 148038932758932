//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'authorization',
    inject: ['showLoading', 'hideLoading'],
    data() {
        return {
            userRoleReqVO: {
                userId: '',
                roleIds: []
            },
            info: {
                key: "roleId",
                label: 'roleName',
            }
        }
    },
    computed: {
        userRoleInfo() {
            return this.$store.state['userManagement'].userRoleInfo;
        },
        activeItem() {
            return this.$store.state['userManagement'].activeItem;
        }
    },
    async created() {
        this.userRoleReqVO.userId = this.activeItem.id;
        this.userRoleInfo.forEach(item => {
            if (item.isMy === 1) {
                this.userRoleReqVO.roleIds.push(item.roleId);
            }
        })
    },
    methods: {
        /**
         * 授权
         */
        async authorizeHandle() {
            this.$confirm(`是否确认授权?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
            }).then(async () => {
                this.showLoading();
                let changeRes = await this.$store.dispatch('userManagement/changeUserRole',
                    this.userRoleReqVO);
                this.hideLoading();
                let type = 'error';
                if (changeRes.code === 200) {
                    type = 'success';
                }
                this.$notify({
                    title: changeRes.code,
                    message: changeRes.msg,
                    type: type,
                    duration: 2000,
                    position: 'bottom-right',
                });
            }).catch(() => {
            });
        },

        /**
         * 取消
         */
        goList() {
            this.$emit('hideRoleAuthorization', false);
        },
    }
}
