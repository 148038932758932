//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {VaildPhoneNumber, VaildEmail} from "../../../utils/regular"
import moment from "moment";
    export default {
        data() {
            let validateEmail = (rule, value, callback) => {
                if(value) {
                    let email = VaildEmail(value);
                    if (!email) {
                        callback(new Error('请输入正确的邮箱'));
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            };
            let validatePhoneNumber = (rule, value, callback) => {
                if(value) {
                    let phoneNumber = VaildPhoneNumber(value);
                    if (!phoneNumber) {
                        callback(new Error('请输入正确的电话号码'));
                    } else {
                        callback();
                    }
                } else {
                    callback();
                }
            };
            return {
                addForm: {
                    username: '',
                    birth: '',
                    nickname: '',
                    phoneNumber: '',
                    email: '',
                    sex: null,
                    id: 0,
                    departmentId: null,
                    deptArray: [0]
                },
                rules: {
                    username: [
                        {required: true, message: '请输入用户名'}
                    ],
                    nickname: [
                        {required: true, message: '请输入昵称'}
                    ],
                    sex: [
                        {required: true, message: '请选择状态'}
                    ],
                    departmentId: [
                        {required: true, message: '请选择所属部门'}
                    ],
                    email: [
                        {required: false, validator: validateEmail, trigger: 'blur'}
                    ],
                    phoneNumber: [
                        {required: false, validator: validatePhoneNumber, trigger: 'blur'}
                    ],
                },
                props: {
                    // expandTrigger: 'hover',
                    checkStrictly: true,
                    value: 'id',
                    label: 'name',
                    children: 'childrenList'
                },
            };
        },
        computed: {
            activeItemUser() {
                return this.$store.state['userManagement'].activeItem;
            },
            deptList() {
                return this.$store.state["department"].treeList;
            }
        },
        inject: ['showLoading', 'hideLoading'],
        async created() {
            for (let i in this.addForm) {
                this.addForm[i] = this.activeItemUser[i];
            }
        },
        methods: {
            goList() {
                this.$emit('hideModify', false);
            },
            //级联选择器获得焦点
            selectFocus() {
                this.$store.dispatch("department/getTree");
            },
            //级联选择器触发子菜单
            handleChangeSelect(val) {
                this.addForm.departmentId = Number(val.slice(-1));
            },
            //提交修改用户信息数据
            submitForm() {
                this.$refs["addForm"].validate(async (valid) => {
                    if (valid) {
                        this.showLoading();
                        if(this.addForm.birth) {
                            this.addForm.birth = moment(this.addForm.birth).format("YYYY-MM-DD HH:mm:ss");
                        }
                        let addRes = await this.$store.dispatch(
                            "userManagement/modifyUserInformation", this.addForm
                        );
                        this.hideLoading();
                        let type = 'error';
                        if (addRes.code === 200) {
                            type = 'success';
                            this.goList();
                            this.$parent.$parent.$refs.list.search();
                        }
                        this.$notify({
                            title: addRes.code,
                            message: addRes.message,
                            type: type,
                            duration: 2000,
                            position: "bottom-right"
                        });
                    }
                });
            },
            resetForm() {
                this.$refs["addForm"].resetFields();
            }
        },
        // mounted () {
        //     this.selectFocus();
        // },
    }
