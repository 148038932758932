//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {VaildPhoneNumber, VaildEmail} from "../../../utils/regular"
import moment from 'moment'

export default {
    inject: ['showLoading', 'hideLoading'],
    data() {
        let validateEmail = (rule, value, callback) => {
            if(value) {
                let email = VaildEmail(value);
                if (!email) {
                    callback(new Error('请输入正确的邮箱'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        let validatePhoneNumber = (rule, value, callback) => {
            if(value) {
                let phoneNumber = VaildPhoneNumber(value);
                if (!phoneNumber) {
                    callback(new Error('请输入正确的电话号码'));
                } else {
                    callback();
                }
            } else {
                callback();
            }
        };
        let validatePassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.addForm.passwordRe !== '') {
                    this.$refs.addForm.validateField('passwordRe');
                }
                callback();
            }
        };
        let validatePasswordRe = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.addForm.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            addForm: {
                username: '',
                nickname: '',
                phoneNumber: '',
                birth: '',
                email: '',
                sex: 0,
                departmentId: null,
                password: '',
                passwordRe: '',
                status: 1,
            },
            props: {
                // expandTrigger: 'hover',
                checkStrictly: true,
                value: 'id',
                label: 'name',
                children: 'childrenList'
            },
            rules: {
                username: [
                    {required: true, message: '请输入用户名'}
                ],
                nickname: [
                    {required: true, message: '请输入昵称'}
                ],
                sex: [
                    {required: true, message: '请选择状态'}
                ],
                departmentId: [
                    {required: true, message: '请选择所属部门'}
                ],
                email: [
                    {required: false, validator: validateEmail, trigger: 'blur'}
                ],
                phoneNumber: [
                    {required: false, validator: validatePhoneNumber, trigger: 'blur'}
                ],
                password: [
                    { required: true,validator: validatePassword, trigger: 'blur' }
                ],
                passwordRe: [
                    {required: true, validator: validatePasswordRe, trigger: 'blur' }
                ],
                status: [
                    {required: true, message: '请选择状态'}
                ]
            },
        };
    },
    computed: {
        deptList() {
            return this.$store.state["department"].treeList;
        }
    },
    methods: {
        goList() {
            this.$emit('hideAdd', false);
        },
        //级联选择器获得焦点
        selectFocus() {
            this.$store.dispatch("department/getTree");
        },
        //级联选择器触发子菜单
        handleChangeSelect(val) {
            this.addForm.departmentId = Number(val.slice(-1));
        },
        //提交新增用户信息
        submitForm() {
            this.$refs["addForm"].validate(async (valid) => {
                if (valid) {
                    if(this.addForm.birth) {
                        this.addForm.birth = moment(this.addForm.birth).format('YYYY-MM-DD HH:mm:ss');
                    }
                    this.showLoading();
                    let addRes = await this.$store.dispatch(
                        "userManagement/addUserInformation", this.addForm
                    );
                    this.hideLoading();
                    let type = 'error';
                    if (addRes.code === 200) {
                        type = 'success';
                        this.goList();
                        this.$parent.$parent.$refs.list.search();
                    }
                    this.$notify({
                        title: addRes.code,
                        message: addRes.msg,
                        type: type,
                        duration: 2000,
                        position: "bottom-right"
                    });
                }
            });
        },
        resetForm() {
            this.$refs["addForm"].resetFields();
        },
    }
}
