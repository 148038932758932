//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import List from "./list";
import Add from "./add";
import Modify from "./modify";
import Authorization from './authorization'

export default {
	components:{
		List,
        Add,
        Modify,
        Authorization
	},
    data(){
        return{
            modifyVisible: false,
            addVisible:false,
            roleAuthorizationVisible:false,
        }
    },
    methods:{
        handlerModify(bool){
            this.modifyVisible = bool;
        },
        handlerAdd(bool){
            this.addVisible = bool;
        },
        handleRoleAuthorization(bool) {
            this.roleAuthorizationVisible = bool;
        },
    }
}
